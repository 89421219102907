import React from 'react';

/*

isSelected : false,
invoiceDate: e.invoiceDate,
dueDate: e.dueDate,
debitorId: e.debitor.debitorId,
debitorName: e.debitor.debitorName,
invoiceNumber: e.invoiceNumber,
invoiceText: e.invoiceText, 
invoiceAmount: e.invoiceAmount

 */

export const FakturaHeaderComponent = (props) => {

    const t1IndicaterIcon = props.t1IndicaterIcon
    const t1ChangeSortField = props.t1ChangeSortField

    return (
        <thead>
            <tr>
                <th onClick={() => t1ChangeSortField('isSelected', 'num')}>{props.localeMessages['GHF_velg']}{t1IndicaterIcon('isSelected')}</th> 
                <th onClick={() => t1ChangeSortField('invoiceDate', 'datetime')}>{props.localeMessages['GHF_invoiceDate']}{t1IndicaterIcon('invoiceDate')}</th>
                <th onClick={() => t1ChangeSortField('dueDate', 'datetime')}>{props.localeMessages['GHF_dueDate']}{t1IndicaterIcon('dueDate')}</th>
                <th onClick={() => t1ChangeSortField('product', 'string')}>{props.localeMessages['GHF_product']}{t1IndicaterIcon('product')}</th>
                <th onClick={() => t1ChangeSortField('debitorId', 'string')}>{props.localeMessages['GHF_debitorId']}{t1IndicaterIcon('debitorId')}</th>
                <th onClick={() => t1ChangeSortField('debitorName', 'string')}>{props.localeMessages['GHF_debitorName']}{t1IndicaterIcon('debitorName')}</th>
                <th onClick={() => t1ChangeSortField('invoiceNumber', 'string')}>{props.localeMessages['GHF_invoiceNumber']}{t1IndicaterIcon('invoiceNumber')}</th>
                <th onClick={() => t1ChangeSortField('invoiceText', 'string')}>{props.localeMessages['GHF_invoiceText']}{t1IndicaterIcon('invoiceText')}</th>
                <th onClick={() => t1ChangeSortField('invoiceAmount', 'num')}>{props.localeMessages['GHF_invoiceAmount']}{t1IndicaterIcon('num')}</th>                                                     
            </tr>
        </thead>
    )
}