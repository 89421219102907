import React from 'react';
import { FormattedDate } from 'react-intl'
import { Container, Row, Col, Button } from "react-bootstrap";
import { BfRowForm } from '../../../Styling/styledForm/styledBlueForm'
import { StyledKfTable, StyledLink } from './../../../Styling/styledTable/StyledTable';
import { Link } from 'react-router-dom';
import { ArticleArea } from '../../../Styling/Layout/ContentArea';

const DebitorList = (props) => {

  const localMessages = props.localeMessages

  const printHeader = (t1IndicaterIcon, t1ChangeSortField) => {
    return (
      <thead>
        <tr>
          <th onClick={() => t1ChangeSortField('birthDate', 'string')}>{localMessages['GH_birthDate']}{t1IndicaterIcon('birthDate')}</th>
          <th onClick={() => t1ChangeSortField('debtorIdentificationNumber', 'string')}>{localMessages['GH_caseNumber']}{t1IndicaterIcon('debtorIdentificationNumber')}</th>
          <th onClick={() => t1ChangeSortField('debtorName', 'string')}>{localMessages['GH_debtorName']}{t1IndicaterIcon('debtorName')}</th>
          <th onClick={() => t1ChangeSortField('debtorNo', 'string')}>{localMessages['GH_debtorNo']}{t1IndicaterIcon('debtorNo')}</th>
          <th onClick={() => t1ChangeSortField('email', 'string')}>{localMessages['GH_email']}{t1IndicaterIcon('email')}</th>
          <th onClick={() => t1ChangeSortField('postalCode', 'string')}>{localMessages['GH_postalCode']}{t1IndicaterIcon('postalCode')}</th>
          <th onClick={() => t1ChangeSortField('postalPlace', 'string')}>{localMessages['GH_postalPlace']}{t1IndicaterIcon('postalPlace')}</th>
        </tr>
      </thead>
    )
  }

  const selectDebitor = model => {
    console.log('model selected', model)
    props.selectDebitor(model)
  }

  const printRow = (model) => {

    const DateOrBlank = (birthDate) => {

      if (!birthDate) {
        return ('')
      } else {
        return (
          <FormattedDate value={birthDate} day="2-digit" month="2-digit" year="numeric" />
        )
      }

    }

    return (
      <tr key={model.i}>

        <td>{DateOrBlank(model.birthDate)}</td>
        <td>{model.debtorIdentificationNumber}</td>
        <td><StyledLink to="#" variant="link" onClick={() => selectDebitor(model)} >{model.debtorName}</StyledLink></td>
        <td>{model.debtorNo}</td>
        <td>{model.email}</td>
        <td>{model.postalCode}</td>
        <td>{model.postalPlace}</td>
      </tr>

    )
  }

  return (
    <>

      <Container fluid="true">
        <ArticleArea>
          <h1>{localMessages['arth']}</h1>
          <p>{localMessages['artd1']}</p>
          <p>{localMessages['artd2']}</p>
          <p>{localMessages['artd3']}</p>
        </ArticleArea>
      </Container>


      <Container fluid="true">

        <BfRowForm>

          <Col xs={12} md={12}>

            <props.PaginationDropdown />
            <StyledKfTable>
              {printHeader(props.t1IndicaterIcon, props.t1ChangeSortField)}

              <tbody>
                <props.Paginate>
                  {/* paginate forventer en funksjon */}
                  {row => printRow(row)}
                </props.Paginate>
              </tbody>
            </StyledKfTable>
            <br />
            <props.PaginationIndex />
            <br /><br />
            <props.PaginationNav />

          </Col>

        </BfRowForm>

      </Container>

    </>
  )
}

export default React.memo(DebitorList)



