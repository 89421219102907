export const messages = {
    nb: {    

        TS1M_Overskrift: `Er saken feilsendt?`,
        TS1M_Ok: `Ja, fortsett`,
        TS1M_Avbryt: `Nei, Avbryt`,
        TS1M_Ja: `Ja, fortsett`,
        TS1M_Nei: `Nei, avbryt`, 

        TS2M_Overskrift: `Er saken feilsendt?`,
        TS2M_terminationCause: 'Årsak',
        TS2M_Ok: `Trekk saken`,
        TS2M_Avbryt: `Avbryt`,
        TS2M_Melding: `Saken blir vurdert avsluttet og dere kan bli belastet i henhold til samarbeidsavtalen.`,
      
        toastGetCaseDetailsOk: 'Sak hentet fra databasen OK.',
        toastGetCaseDetailsFailed: 'Henting av saksdetaljer feilet. ',

        toastTrekkSakNotAllowed: 'Denne saken kan ikke trekkes.',
        toastTrekkSakFailed: 'En feil oppstod, saken kunne ikke trekkes.',
        toastTrekkSakDenied: 'Denne saken kan ikke trekkes. ',
        toastTrekkSakOk: 'Saken er trukket.',
                
    }
    
} 