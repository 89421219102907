import React from 'react';

/*
    isSelected: false,
    disabled: '',
    closingListId: e.closingListId,
    customerNo: e.customerNo,
    debtorAddress: e.debtorAddress,
    debtorCategory: e.debtorCategory,
    debtorName: e.debtorName,
    debtorNo: e.debtorNo,
    debtorPostalNumber: e.debtorPostalNumber,
    debtorPlace: e.debtorPlace,
    firstInvoiceDate: e.firstInvoiceDate,
    meeterPointId: e.meeterPointId,
    regDate: e.regDate,
    remainingPrincepal: e.remainingPrincepal,
    remainingTotal: e.remainingTotal,
    status: e.status,
    utilityAddress: e.utilityAddress,
    closingDate: e.closingDate,
 */

export const StengelisteHeaderComponent = (props) => {

    const t1IndicaterIcon = props.t1IndicaterIcon
    const t1ChangeSortField = props.t1ChangeSortField
    const showCheckbox = props.showCheckbox
    const showPutOnHold = props.showPutOnHold
    const showSelectedDate = props.showSelectedDate
    const showPaymentDate = props.showPaymentDate

    return (
        <thead>
            <tr>
                <th></th> {/* Tom header over expand ikonene */}
                {showCheckbox && (<th onClick={() => t1ChangeSortField('isSelected', 'num')}>{props.localeMessages['GHS_velg']}{t1IndicaterIcon('isSelected')}</th>)}
                <th onClick={() => t1ChangeSortField('customerNo', 'num')}>{props.localeMessages['GHS_customer_ref']}{t1IndicaterIcon('customerNo')}</th>
                <th onClick={() => t1ChangeSortField('meeterPointId', 'num')}>{props.localeMessages['GHS_measuring_ID']}{t1IndicaterIcon('meeterPointId')}</th>
                <th onClick={() => t1ChangeSortField('debtorRating', 'num')}>{props.localeMessages['GHS_rating']}{t1IndicaterIcon('debtorRating')}</th>
                <th onClick={() => t1ChangeSortField('debtorName', 'string')}>{props.localeMessages['GHS_customer_name']}{t1IndicaterIcon('debtorName')}</th>
                <th onClick={() => t1ChangeSortField('utilityAddress', 'string')}>{props.localeMessages['GHS_utility_address']}{t1IndicaterIcon('utilityAddress')}</th>
                <th style={{ textAlign:"right", paddingRight: 50 }} onClick={() => t1ChangeSortField('remainingTotal', 'num')}>{props.localeMessages['GHS_closing_amount']}{t1IndicaterIcon('remainingTotal')}</th>  {/* textAlign for å få header og rows til å være under hverandre, padding for å skape litt avstand til neste kolonne  */}
                <th onClick={() => t1ChangeSortField('regDate', 'datetime')}>{props.localeMessages['GHS_date_closingList']}{t1IndicaterIcon('regDate')}</th>
                {showSelectedDate && (<th onClick={() => t1ChangeSortField('closingDate', 'datetime')}>{props.localeMessages['GHS_selected_date']}{t1IndicaterIcon('closingDate')}</th>)}
                {showPaymentDate && (<th onClick={() => t1ChangeSortField('paymentDate', 'datetime')}>{props.localeMessages['GHS_payment_date']}{t1IndicaterIcon('paymentDate')}</th>)}
                {showPutOnHold && (<th></th>)} {/* Tom header over På hold ikon-knappen, kun synlig i På stengeliste tab'en */}
            </tr>
        </thead>
    )
}