import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { toast } from 'react-toastify';
import _ from 'lodash'

export const gncActions = {
    LOADING: 'NEWCASE_LOADING',
    ERROR: 'NEWCASE_ERROR',
    DEBITORSFETCHED: 'DEBITORSFETCHED',
    DEBITORSFILTERED: 'DEBITORSFILTERED',
    SELECTDEBITOR: 'SELECTDEBITOR',
    SETFAKTURAHEADER: 'SETFAKTURAHEADER',
    VISUPLOADFAKTURA: 'VISUPLOADFAKTURA',
    EDITFAKTURA: 'EDITFAKTURA',
    FAKTURADELETED: 'FAKTURADELETED',
    EMPTYFAKTURA: 'EMPTYFAKTURA',
    FAKTURAUPLOADED: 'FAKTURAUPLOADED',
    FAKTURAFINALIZED: 'FAKTURAFINALIZED',
    NEWDEBITOR: 'NEWDEBITOR',
    NO_DATA: 'NO_DATA',
    RESETCONTAINER: 'RESETCONTAINER',
    SETBASICDATA: 'SETBASICDATA'
}

export const displayForms = {
    DEBITORFILTER: 'DEBITORFILTER',
    EDITDEBITOR: 'EDITDEBITOR',
    FAKTURAUPLOAD: 'FAKTURAUPLOAD',
    FAKTURAUPLOADEDIT: 'FAKTURAUPLOADEDIT',
    CONFIRM: 'CONFIRM',
    RECEIPT: 'RECEIPT'
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

const mapDebtorList = (debtorList) => {
    let result = debtorList.map((e, i) => {
        let _filter = Object.values(e).join(";").toUpperCase();
        return {
            ...e,
            filter: _filter,
            debtorName: `${e.firstName} ${e.lastName}`.trim(),
            i: i
        }
    })
    return result
}

const newCaseLoading = () => ({
    type: gncActions.LOADING
});

const newCaseFailure = error => ({
    type: gncActions.ERROR,
    data: { error }
});

const setBasicData = data => ({
    type: gncActions.SETBASICDATA,
    data: data
})

const debitorsFetched = data => ({
    type: gncActions.DEBITORSFETCHED,
    data: data 
})

const debitorsFiltered = data => ({
    type: gncActions.DEBITORSFILTERED,
    data: data
})

const setNewDebitor = data => ({
    type: gncActions.NEWDEBITOR,
    data: data
})

const setSelectedDebitor = data => ({
    type: gncActions.SELECTDEBITOR,
    data: data
})

const showVisFaktura = data => ({
    type: gncActions.VISUPLOADFAKTURA,
    data: data
})

// ********** NB SJEKK *** ikke implementert i reducerem
const hideModal = () => ({
    type: gncActions.HIDEMODAL
});

const visUploadFaktura = data => ({
    type: gncActions.VISUPLOADFAKTURA,
    data: data
})

const setFakturaHeader = data => ({
    type: gncActions.SETFAKTURAHEADER,
    data: data
})

const fakturaUploaded = data => ({
    type: gncActions.FAKTURAUPLOADED,
    data: data
})

const fakturaFinalized = data => ({
    type: gncActions.FAKTURAFINALIZED,
    data: data
})

const setEditFaktura = data => ({
    type: gncActions.EDITFAKTURA,
    data: data
})

const setFakteader = data => ({
    type: gncActions.SETFAKTURAHEADER,
    data: data
})

const setFaktDeleted = data => ({
    type: gncActions.FAKTURADELETED,
    data: data
})

const setEmptyFaktura = data => ({
    type: gncActions.EMPTYFAKTURA,
    data: data
})

const setResetContainer = data => ({
    type: gncActions.RESETCONTAINER,
    data: data
})

export const newCaseGetBasicData = (showToasts, messages) => {

    return dispatch => {
        
        dispatch(newCaseLoading())    
        
        axiosTokenInstance
        ({
            method: 'GET',
            url: `api/sak/GetBasicData`,
        })
        .then((result) => {
            //console.log(result)
            if (result.status !== 200) {
                (showToasts && toast.error(messages['toastGetBasicDataFailed'], {autoClose: false}))
            } else {                
                let data = JSON.parse(result.data)
                //console.log(data)
                if (data.errorCode === "000") {
                    (showToasts && toast.warning(messages['toastGetBasicDataOk']))                    
                    dispatch(setBasicData({ countryList: data.countryList, currencyList: data.currencyList, postalList: data.postalList }))
                } else {
                    (showToasts && toast.warning(messages['toastGetBasicDataFailed'] + ', ' + result.data.errorMessage))
                    dispatch(newCaseFailure(messages['toastGetBasicDataFailed'] + ', ' + result.data.errorMessage))
                }
            }
        })
        .catch((err) => {
            let errormessage = messages['toastGetBasicDataFailed'] + ' ' + getErrorMessage(err)
            toast.error(errormessage, {autoClose: false})            
            dispatch(newCaseFailure(errormessage))
        })
    }
}


export const getDebitorList = (creditorNo, showToasts, messages) => {

    return dispatch => {        
        
        if (creditorNo === '') {
            return
        }
                
        dispatch(newCaseLoading())
        
        axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/sak/GetDebtorList/${creditorNo}`,
        })
        .then((result) => {
            //console.log(result)
            if (result.status !== 200) {
                (showToasts && toast.error(messages['toastGetDebitorsFailed'], {autoClose: false}))                
                dispatch(newCaseFailure(messages['toastGetDebitorsFailed']))
            } else {
                
                let data = JSON.parse(result.data)
                //console.log(data)
                if (data.errorCode === "000") {
                    (showToasts && toast.warning(messages['toastGetDebitorsOk']))
                    
                    // Map om                   
                    let debtorList = mapDebtorList(data.debtorList)                    
                    dispatch(debitorsFetched({ kreditorId: creditorNo, debitorList: debtorList, defaultProduct: data.defaultProduct }))
                } else {
                    (showToasts && toast.warning(messages['toastGetDebitorsFailed'] + ', ' + result.data.errorMessage))
                    //dispatch({ type: actions.HIDE_MODAL, data: null })
                }
            }
        })
        .catch((err) => {
            let errormessage = messages['toastGetDebitorsFailed'] + ' ' + getErrorMessage(err) + ` (/api/sak/GetDebtorList/${creditorNo})`
            toast.error(errormessage, {autoClose: false})
            //dispatch({ type: actions.ERROR, data: errormessage })
            dispatch(newCaseFailure(errormessage))
        })
    }
}


export const filterDebitorList = (filterstring, state, showToasts, messages) => {

    return dispatch => {

        const upperFilter = filterstring.toUpperCase()
        
        let filteredDebitors = []
        
        if (state.debitorList.length > 0) {
            
            dispatch(newCaseLoading())
            
            if (filterstring) {
                filteredDebitors = state.debitorList.filter((x) => {
                    return (x.filter.indexOf(upperFilter) !== -1)
                })
            } else {
                filteredDebitors = state.debitorList
            }
            
            dispatch(debitorsFiltered({
                debitorListFiltered: filteredDebitors,
                filterString: filterstring
            }))
        }
    }
}

export const setDebitor = (debitorModel) => {

    return dispatch => {
        dispatch(newCaseLoading())
        dispatch(setSelectedDebitor({ selectedDebitor: debitorModel }))
    }
}

export const newDebitor = (showToasts, messages) => {

    return dispatch => {
        dispatch(newCaseLoading())        
        dispatch(setNewDebitor({}))        
    }
}

// Videre til neste form
export const visFakturaListe = (preCaseId) => {

    return dispatch => {

        dispatch(newCaseLoading())
        dispatch(visUploadFaktura(preCaseId))        
    }
}

/* 
  Post creditor for å få en Precase id
  Om Ok skal kjøres for å gå videre til neste bilde
*/
export const postNySakDebitor = (formData, showToasts, messages) => {

    return dispatch => {

        if (formData.debtorType === 'Privat') {
            dispatch(setFakturaHeader('Kreditor: ' + formData.CreditorNo + ', debitor: ' + formData.firstName + ' ' + formData.lastName))
        } else {
            dispatch(setFakturaHeader('Kreditor: ' + formData.CreditorNo + ', debitor: ' + formData.debtorName))
        }

        //**** @@@@@ dispatchSetFakturaHeader(formData.CreditorNo + ' ' + formData.debtorName, dispatch, actions, showToasts)
        
        
        dispatch(newCaseLoading())
        
        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/sak/PostSingleCaseDebitor`,
            data: formData
        })
        .then((result) => {
            //console.log(result)
            
            if (result.status !== 200) {
                
                (showToasts && toast.error(messages['toastNySakPostDebitorFailed'], {autoClose: false}))
                //dispatch({ type: actions.ERROR, data: messages['toastNySakPostDebitorFailed'] })
                dispatch(newCaseFailure(messages['toastNySakPostDebitorFailed'] ))
                
            } else {
                
                if (result.data.errorCode === "000") {
                    //console.log(result.data.preCaseId)
                    const preCaseId = result.data.preCaseId
                    
                    if (_.has(result.data, 'preCaseId')) {
                        (showToasts && toast.warning(messages['toastNySakPostDebitorOk'] + ' (precaseId:' + preCaseId + ').'))
                        dispatch(visFakturaListe(preCaseId))
                    } else {
                        (showToasts && toast.warning(messages['toastNySakPostDebitorFailed'] + ', ' + + ', ' + result.data.errorMessage))
                        //dispatch({ type: actions.ERROR, data: messages['toastNySakPostDebitorFailed'] + + ', ' + result.data.errorMessage })
                        dispatch(newCaseFailure(messages['toastNySakPostDebitorFailed'] + + ', ' + result.data.errorMessage))
                    }
                    
                } else {
                    (showToasts && toast.warning(messages['toastNySakPostDebitorFailed'] + ', ' + + ', ' + result.data.errorMessage))
                    //dispatch({ type: actions.ERROR, data: messages['toastNySakPostDebitorFailed'] + + ', ' + result.data.errorMessage })
                    dispatch(newCaseFailure(messages['toastNySakPostDebitorFailed'] + + ', ' + result.data.errorMessage))
                }
            }
        })
        .catch((err) => {

            let errormessage = messages['toastNySakPostDebitorFailed'] + ' ' + getErrorMessage(err)
            toast.error(errormessage, {autoClose: false})
            //dispatch({ type: actions.ERROR, data: errormessage })
            dispatch(newCaseFailure(errormessage))
            console.log(err)
        })
    }
}

export const startUploadFaktura = (formData, fakturaListe, lineNo, clearForm, showToasts, messages ) => {

    return dispatch => { 

        dispatch(newCaseLoading())
        
        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/sak/PostSingleCaseInvoice`,
            data: formData
        })
        .then((result) => {
            
            if (result.status !== 200) {
                
                (showToasts && toast.error(messages['toastNySakFakturaUploadFailed'], {autoClose: false}))
                //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaUploadFailed'] })
                dispatch(newCaseFailure(messages['toastNySakFakturaUploadFailed']))
                
            } else {

                if (result.data.errorCode === "000") {
                    
                    (showToasts && toast.warning(messages['toastNySakFakturaUploadOk']))
                    //dispatch({ type: actions.FAKTURAUPLOADED, data: { invoiceList: fakturaListe, lineNo: lineNo } })
                    dispatch(fakturaUploaded({ invoiceList: fakturaListe, lineNo: lineNo }))
                    
                } else {
                    (showToasts && toast.warning(messages['toastNySakFakturaUploadFailed'] + ', ' + + ', ' + result.data.errorMessage))
                    //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaUploadFailed'] + + ', ' + result.data.errorMessage })
                    dispatch(newCaseFailure(messages['toastNySakFakturaUploadFailed'] + + ', ' + result.data.errorMessage))
                }
            }
        })
        .catch((err) => {
            
            let errormessage = messages['toastNySakPostDebitorFailed'] + ' ' + getErrorMessage(err)
            toast.error(errormessage, {autoClose: false})
            //dispatch({ type: actions.ERROR, data: errormessage })
            dispatch(newCaseFailure(errormessage))
            console.log(err)
        })
    }
}

export const startFinalizeFaktura = (formData, showToasts, messages) => {

    return dispatch => {
        
        dispatch(newCaseLoading())
        
        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/sak/PostSingleCaseFinalize`,
            data: formData
        })
        .then((result) => {
            //console.log(result)
            
            if (result.status !== 200) {
                
                (showToasts && toast.error(messages['toastNySakFakturaFinalizeFailed'], {autoClose: false}))
                //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaFinalizeFailed'] })
                dispatch(newCaseFailure(messages['toastNySakFakturaFinalizeFailed']))
                
            } else {
                
                if (result.data.errorCode === "000") {
                    
                    (showToasts && toast.warning(messages['toastNySakFinalizeOk']))
                    //dispatch({ type: actions.FAKTURAFINALIZED, data: {} })
                    dispatch(fakturaFinalized(result.data.caseNo))
                    
                } else {
                    (showToasts && toast.warning(messages['toastNySakFakturaFinalizeFailed'] + ', ' + + ', ' + result.data.errorMessage))
                    //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaFinalizeFailed'] + + ', ' + result.data.errorMessage })
                    dispatch(newCaseFailure(messages['toastNySakFakturaFinalizeFailed'] + + ', ' + result.data.errorMessage))
                }
            }
        })
        .catch((err) => {
            
            let errormessage = messages['toastNySakPostDebitorFailed'] + ' ' + getErrorMessage(err)
            toast.error(errormessage, {autoClose: false})
            //dispatch({ type: actions.ERROR, data: errormessage })
            dispatch(newCaseFailure(errormessage))
            console.log(err)
        })
    }
}

export const startEditFaktura = (lineNo, showToasts) => {

    return dispatch => {
        dispatch(setEditFaktura({ lineNo: lineNo }))        
    }
}

export const startDeleteFaktura = (formData, fakturaListe, clearForm, messages, showToasts) => {

    return dispatch => {
        
        dispatch(newCaseLoading())
        
        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/sak/DeleteSingleCaseInvoice`,
            data: formData
        })
        .then((result) => {
            
            if (result.status !== 200) {
                
                (showToasts && toast.error(messages['toastNySakFakturaDeletedFailed'], {autoClose: false}))
                //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaDeletedFailed'] })
                dispatch(newCaseFailure(messages['toastNySakFakturaDeletedFailed']))
                
            } else {
                
                if (result.data.errorCode === "000") {                    
                    (showToasts && toast.warning(messages['toastNySakFakturaDeletedOk']))
                    //dispatch({ type: actions.FAKTURADELETED, data: fakturaListe })
                    dispatch(setFaktDeleted(fakturaListe))
                    
                } else {
                    (showToasts && toast.warning(messages['toastNySakFakturaDeletedFailed'] + ', ' + + ', ' + result.data.errorMessage))
                    //dispatch({ type: actions.ERROR, data: messages['toastNySakFakturaDeletedFailed'] + + ', ' + result.data.errorMessage })
                    dispatch(newCaseFailure(messages['toastNySakFakturaDeletedFailed'] + + ', ' + result.data.errorMessage))
                }
            }
        })
        .catch((err) => {
            
            let errormessage = messages['toastNySakFakturaDeletedFailed'] + ' ' + getErrorMessage(err)
            toast.error(errormessage, {autoClose: false})
            //dispatch({ type: actions.ERROR, data: errormessage })
            dispatch(newCaseFailure(errormessage))
            console.log(err)
        })           
    }
}


export const startClearFaktura = () => {
    return dispatch => {
        dispatch(setEmptyFaktura({}))
    }
}

export const resetContainer = () => {

    return dispatch => {
        dispatch(newCaseLoading())
        dispatch(setResetContainer({}))
    }
}
    