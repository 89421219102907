import React, { useState, useEffect } from 'react';
import Axios from 'axios'
import { useUcForm } from '../../hooks/useUcForm'
import { profileFormValidators } from './profileValidators'
import axiosTokenInstance from '../../Instances/axiosTokenInstance'
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth'
import _ from 'lodash'
import LocaleContext from '../../state/contexts/LocaleContext'
import useUserAdminReducer from '../useradmin/useUserAdminReducer'
import { messages } from '../useradmin/UserAdminContainer.i18n.js'


export const useProfil = (localeMessages) => { 

    const locale = React.useContext(LocaleContext);

    const auth = useAuth()

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false)

    const showToasts = true
    
    // Før ble profilinfo hentet fra token, men gir bug ved oppdatering av tlf.nr
    const { state, setCurrentUser } = useUserAdminReducer(messages[locale])
    useEffect(() => {
        setCurrentUser(auth.user.email)                
    }, []) 

    const fixphone = (val) => {
        if (val) {
            let res = val.replace(/\s/g, '') 
            res = res.slice(-8)
            console.log(res)
            return res
        }
        return ""
    }

    useEffect(() => {
        if (state.user.username) {
            setField('Navn', state.user.fullName ?? "")        
            setField('Epost', state.user.username ?? "")
            setField('PhoneNumber', state.user.phoneNumber)// fixphone(state.user.phoneNumber)) 
            setTwoFactorEnabled(state.user.twoFactorEnabled)
            setIsLoading(false)            
        }
    }, [state.user]) 
 
    let formValues = {
        Navn: '',
        Epost: '',
        Passord: '',       
        Passord1: '',       
        Passord2: '',
        PhoneNumber: '',
    }
    
    const formValidators = profileFormValidators(localeMessages)

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid,
        validatorsState } = useUcForm(formValues, formValidators)

    const [extraErrors, setExtraErrors] = useState({})
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    const submitForm = () => {

        let formDontValidate = validateAll();

        if (formState.Passord1 || formState.Passord2) {
            if (!formState.Passord || !formState.Passord1 || !formState.Passord2)
            {
                setErrorMessage(localeMessages['youHavePWErrors'])
                return
            }
        }

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})


        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            //console.log('Canbesubmitted')

            setIsLoading(true)
            setErrorMessage('')
      
            const formData = new FormData();
            formData.append('Name', formState.Navn);
            formData.append('Email', formState.Epost);
            formData.append('PhoneNumber', formState.PhoneNumber);

            // Ikke bytt passord med mindre alle tre er uffylt
            if (formState.Passord && formState.Passord1 && formState.Passord2) {
                formData.append('OldPassword', formState.Passord);
                formData.append('NewPassword', formState.Passord1);
            }

            //console.log(JSON.stringify(submitData))

            axiosTokenInstance
                ({
                    method: 'POST',
                    url: `/api/UserAdmin/UpdateProfile`,
                    data: formData
                })
                .then((result) => {
                    setIsLoading(false)                    
                    console.log(result)

                    if (result.status !== 200) {
                        (showToasts && toast.error(localeMessages['toastSaveError'], {autoClose: false}))
                    } else {
                        (showToasts && toast.warning(localeMessages['toastSaveOk']))
                        //console.log(JSON.stringify(result.data))
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    //loadash is better than if (err.response.status === 400) {
                    if (_.has(err, 'response.data')) {   
                        setErrorMessage(localeMessages['toastSaveError'] + err.response.data)
                        //console.log(err)
                    } else {
                        setErrorMessage(localeMessages['toastSaveError'] + ', statuscode: ' + err.message)
                        //console.log(err)
                    }
                    console.log('error submitting form: ' + errorMessage)
                })
        }

    }

    return { formValues, formValidators, handleChange, formState, validateAll, hasErrors, errorMessages, extraErrors, setExtraErrors, submitForm, isLoading, errorMessage, twoFactorEnabled }

}
