import React, { useState } from "react"
import { Form, Col, Button } from "react-bootstrap"
import {
    StyledModal,    
    StyledModalBody,
    StyledModalFooter,   
} from "../../../Styling/styledModal/styledModals"


const TrekkSak02Modal = (props) => {
      
    const localeMessages =  props.tsFuncs.trekkSakMessages 
      
    const eventHide = props.tsFuncs.cancelTrekkSak
    const onOk = () => props.tsFuncs.showTrekkSak2OK(terminationCause)
   
    const [terminationCause, setTerminationCause] = useState('')
    
    return (

        <StyledModal centered show={true} onHide={eventHide}>

            <StyledModalBody>
                <h4>{localeMessages["TS2M_Overskrift"]}</h4>

                <Form.Group>

                    <Form.Row>
                        <Form.Label column="sm" lg={12}>
                            {localeMessages["TS2M_Melding"]}
                        </Form.Label>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["TS2M_terminationCause"]}
                        </Form.Label>
                        <Col>
                            <Form.Control id="Oppgave" name="Oppgave" as="textarea" rows={5}
                                value={terminationCause} onChange={(e) => setTerminationCause(e.target.value)}
                            />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button variant="secondary" onClick={onOk}>
                    {localeMessages["TS2M_Ok"]}
                </Button>
                <Button variant="secondary" onClick={eventHide}>
                    {localeMessages["TS2M_Avbryt"]}
                </Button>
            </StyledModalFooter>

        </StyledModal>

    )
  
}

export default TrekkSak02Modal
