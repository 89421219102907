import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getCMSbyIndex, getAhlinCMSbyId } from '../../reduxStore/HeadlessCMS/headlessCmsActions';
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './HeadlessCmsContainer.i18n'

const HeadlessCmsContainer = (props) => {

    const showToasts = true

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const state = useSelector(state => state.headlessCMS)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!state.data[props.id]) {
            if (props.id === 'prislister' || props.id === 'avslutningskode') {
            dispatch(getAhlinCMSbyId(props.id, showToasts, localeMessages))
        } else {
            dispatch(getCMSbyIndex(props.id, showToasts, localeMessages))
        }
        }
    }, [props.id, dispatch, showToasts, localeMessages])

    useEffect(() => {
        setIsLoading(state.loading)
    }, [state.loading])

    const getCMSContent = (htmlContent) => {
        if (htmlContent && props.id === 'prislister' || htmlContent && props.id === 'avslutningskode') {
            return (
                <div>
                    {htmlContent.map((item) => (
                        <div key={item.id}>
                            <h2 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h2>
                            <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                        </div>
                    ))}
                </div>
            );
        }    else if (state.data[props.id]) {
            return (
                <div dangerouslySetInnerHTML={{ __html: state.data[props.id] }}></div>
            )
        };
    }
      

        if (isLoading) return <p>Loading...</p>;


        return (
            <ArticleArea>
                {getCMSContent(state.data[props.id])}
            </ArticleArea>
        
        )

}

export default HeadlessCmsContainer