import {useReducer} from 'react'
import { updateObject } from '../utility'
import * as actionTypes from './actionTypes'

const intialState = {
    token: '',
    refreshToken: '',
    role: [],
    user: {
        id: '',
        username: '',
        name: '',
        email: '',
        phonenumber: '',
        passwordUser: '',
        passwordUserId: '',
        twoFactorEnabled: false,
        forceMfaDate: '',
        forceMfaNow: 'false',           
    },
    claims: {
        creditors: []
    },
    mfa: false,
    authenticated: false,
    error: null,
    loading: false
}

export const AuthReducer = () => { 
    const [state, dispatch] = useReducer(reducer, intialState)
    return [state, dispatch] 
}

const authStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true,
        mfa: false,
    })
} 

const authSuccess = (state, action) => {
    return updateObject(state, {
        authenticated: true,
        user: {
            username: action.payload.username,
            id: action.payload.id,
            email: action.payload.email,
            passwordUser: action.payload.passwordUser,
            passwordUserId: action.payload.passwordUser,
            phonenumber: action.payload.phonenumber,
            name: action.payload.name,
            forening: action.payload.assiociationId,
            foreningsNavn: action.payload.assiociationname,
            assiociationname: action.payload.assiociationname,
            bisnodeenabled: action.payload.bisnodeEnabled,
            twoFactorEnabled: action.payload.twoFactorEnbled, 
            forceMfaDate: action.payload.forceMfaDate,
            forceMfaNow: action.payload.forceMfaNow           
        },
        mfa: false,
        token: action.payload.token,
        role: action.payload.role,
        claims: { creditors: action.payload.creditorClaims },
        loading: false,        
    })
}

const authFailed = (state, action) => {
    return updateObject(state, {
        mfa: false,
        error: action.payload.error,
        loading: false
        }
    )
}

const requireMfa = (state, action) => {    
    let result = state
    result.user.twoFactorEnabled = true    
    result.user.forceMfaNow = 'false'
    result.error = action.payload.error
    result.loading = false
    result.mfa = true
    return updateObject(result)    
}

const removeMfa = (state, action) => {
    let result = state 
    result.user.twoFactorEnabled = false
    result.error = action.payload.error
    result.loading = false
    result.mfa = false
    return updateObject(result)
}

const logOut = (state, action) => {
    return updateObject(state, { ...intialState })
}

const reducer = (state, action) => {

    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action)
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
        case actionTypes.AUTH_FAIL: return authFailed(state, action)
        case actionTypes.AUTH_LOGOUT: return logOut(state, action)
        case actionTypes.AUTH_MFA: return requireMfa(state, action)
        case actionTypes.AUTH_NO_MFA: return removeMfa(state, action)
        default:
          return state
      }
    }