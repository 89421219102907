import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import styled from 'styled-components'
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import MeldingsListe from './MeldingsListe';
import NyheterCMS from './NyheterCMS';
import _ from 'lodash'
import { TabWrapper } from '../../../components/tabs/TabWrapper';
import { SumLister } from './SumLister';


export const OversiktTabs = (props) => {

    const state = props.state
    const htmlArticle = props.htmlArticle
    const localeMessages = props.localeMessages
    
    const aO = () => {
        if (_.isEmpty(state.data.oppgaveListe))
            return 0

        return state.data.oppgaveListe.length ? state.data.oppgaveListe.length : 0
    }

    const bO = () => {
        if (_.isEmpty(state.data.besvarteListe))
            return 0

        return state.data.besvarteListe.length ? state.data.besvarteListe.length : 0
    }

    // const [key, setKey] = useState((aO() === 0) ? 'nyheter' : 'oppgliste');
    const [key, setKey] = useState('nyheter');

    // Besvarte og oppgaveliste
    const getBesvarteGrid = () => {
        if (state.data.besvarteListe) {
            return (<MeldingsListe tableData={state.data.besvarteListe} expand={false}/>)
        }
        return (<>no data</>)
    }

    // Besvarte og oppgaveliste
    const getOppgaveListeGrid = () => {
        if (state.data.oppgaveListe) {
            return (<MeldingsListe tableData={state.data.oppgaveListe} expand={true} />)
        }
        return (<>no data</>)
    }

    const getInfo = (htmlContent) => {
        if (htmlContent) {
            return (
                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            )
        }
        else {
            return (<></>)
        }
    }

    const getSumLister = () => {

        return (<SumLister />)

    }

    const getNyheter = () => {
        return (
            <ArticleArea>
                <NyheterCMS articleId={'nyheter'} localeMessages={localeMessages} />
            </ArticleArea>
        )
    }


    return (
        <TabWrapper>

            <Tabs id="oversiktTab" activeKey={key} onSelect={(k) => setKey(k)} style={{ backgroundColor: "white", fontWeight: "400", "color": "rgb(0, 62, 96)" }} >

                <Tab eventKey="oppgliste" title={`${localeMessages['TaC_T1']} (${aO()})`}>
                    {getOppgaveListeGrid()}
                </Tab>

                <Tab eventKey="nyheter" title={`${localeMessages['TaC_T4']}`}>
                    {getNyheter()}
                </Tab>

                <Tab eventKey="informasjon" title={`${localeMessages['TaC_T3']}`}>

                    <iframe
                        src="https://www.kred.no/inkassoakademiet-master/"
                        height="3000px"
                        width="1767px"
                        title="description"
                    >
                    </iframe>

                    <ArticleArea>
                        <br />
                        {getInfo(htmlArticle)}
                        <br />
                    </ArticleArea>

                </Tab>

                <Tab eventKey="besvarte" title={`${localeMessages['TaC_T2']} (${bO()})`}>
                    {getBesvarteGrid()}
                </Tab>


                {/*
                <Tab eventKey="lister" title={`${localeMessages['TaC_T4']}`}>
                    {getSumLister()}
                </Tab>
                */}

            </Tabs>
        </TabWrapper>
    )
}

