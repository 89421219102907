import React, {useState} from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl'
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';
import styled from 'styled-components';
import { HoverBox, HoverBoxNoWidthChange } from '../../../components/fullTextOnHover/HoverBox';



export const FakturaRowComponent = (props) => {

    const [checked, setChecked] = React.useState(props.row.isSelected);
    const [isShown, setIsShown] = useState(false);

    const handleChange = () => {
        const model = props.row;
        props.toggleRow(model.i, !model.isSelected)
    };


    const asDate = (val) => {
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    // Logikk for å vente litt før man viser faktura,
    // unngår flickering ved flytting av markøren
    let timeoutId;
    const mouseEnter = () => {
        if (!timeoutId) {
            timeoutId = window.setTimeout(function() {
                timeoutId = null; // EDIT: added this line
                setIsShown(true)      
           }, 200);
        }
    }
    const mouseLeave = () => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = null;
        }
        setIsShown(false)
    }
    
    const model = props.row

    return (
        <>
            <tr key={model.i.toString()}>
                <td>
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                        disabled={model.disabled}
                    />
                    {model.disabled && <>&nbsp;(Innsendt)</>}
                </td>
                <td>{asDate(model.invoiceDate)}</td>
                <td>{asDate(model.dueDate)}</td>
                <td>{model.product}</td>
                <td>{model.debitorId}</td>                   
                <td>
                    <HoverBox minWidth={200}>
                        {model.debitorName}
                    </HoverBox>
                </td>
                <td>{model.invoiceNumber}</td>
                <td>
                    <HoverBoxNoWidthChange minWidth={300}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                    >
                        {model.invoiceText}
                    </HoverBoxNoWidthChange>
                </td>
                <td align="right">
                    {<FormattedNumber
                        value={model.invoiceAmount}
                        style='decimal'
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                    />}
                </td>
            </tr>
            {isShown && (
                <tr>
                    <td></td>
                    <td colspan={7}>
                        <p>
                            <b>Fakturatekst</b>
                        </p>
                        <p>
                        {model.invoiceText}
                        </p>
                        <p></p>
                    </td>
                </tr>)
            }           
        </>
    )
}  
