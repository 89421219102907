import React, { forwardRef }  from "react";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.no";
import { Form, Col, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import { BfFormLabel, BfFormLabelSm, BfFormErrorSm, BfFormControl, BfSelect } from '../styledForm/styledBlueForm'
import DatePicker from "react-datepicker";
import { dateToString } from "../../util/date/dateRoutines";

export const BfColInput = (props) => {
    const {colspan, ...inputProps} = props
    return (
        <Col xs={12} md={colspan}>
            <FormInput {...inputProps} />
        </Col>
    )
}

export const Picker = (props) => {
            
    //const [startDate, setStartDate] = useState(defaultDate());
   
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div 
            style={{ marginLeft: "7px", marginTop: "6px"}}    
            className="example-custom-input"
            onClick={onClick}
            ref={ref}>
            <span className="fa fa-calendar"/> 
        </div>
    ));

    const defaultDate = () => {

        if (props.value) {
            //const formatDate = d => `${d.substring(6, 10)}-${d.substring(3, 5)}-${d.substring(0, 2)}`
            let year = props.value.substring(6, 10)
            let mon = props.value.substring(3, 5)
            let day = props.value.substring(0, 2)
            
            return new Date(year, mon-1, day);
        }
        return new Date()
    }

    const dateChanged = (dateObj, evt) => {
       
        var newDate = dateToString(dateObj)

        let e = {
            target: {
                name: props.name,
                value: newDate,
            }
        }

        props.onChange(e)
    }

    return (
        <DatePicker
            selected={defaultDate()}
            onChange={dateChanged}
            customInput={<ExampleCustomInput />}
      />
    );
  };

export const FormInput = ({
    name,
    inputType,
    formState,
    onChange,
    onBlur,
    error,
    children,
    messages,
    errorMessages,
    extraErrors,
    selectOptions,
    ...props
}) => {
    
    const getPlaceHolder = () => {
        let result = ""
        if (!props.disabled) {
            result = messages[name + "Placeholder"]
        }
        return result
    }

    const showBorder = (fieldName) => {
        let allErrors = { ...errorMessages, ...extraErrors }
        let error = allErrors[fieldName]
        if (!error)
            return {}

        return { border: '2px solid #842029' }
    }

    const showCleaveBorder = (fieldName) => {
        let allErrors = { ...errorMessages, ...extraErrors }
        let error = allErrors[fieldName]
        if (!error)
            return {
                fontFamily: "Fellix, sans-serif",
                fontSize: '20px',
                border: "0px solid #ced4da",
                height: "calc(1.5em + .75rem + 2px)",
                padding: ".375rem .75rem",
                borderRadius: "0.5em"
            }

        return {
            fontFamily: "Fellix, sans-serif",
            fontSize: '20px',
            height: "calc(1.5em + .75rem + 2px)",
            padding: ".375rem .75rem",
            borderRadius: "0.5em",
            border: '2px solid #842029'
        }
    }

    if (inputType === 'textarea') {
        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel>
                <Form.Control as="textarea" 
                    id={name}
                    name={name}
                 
                    rows={3}
                    placeholder={getPlaceHolder()}
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showBorder(name)}
                    disabled={props.disabled}
                />
                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }
    else if (inputType === 'select') {

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel>
                <BfSelect
                    id={name}
                    as={inputType}
                    name={name}                                        
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showBorder(name)}
                    disabled={props.disabled}                    
                >
                    {selectOptions()}
                </BfSelect>

                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }
    else if (inputType === 'cleaveDate') {                

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel><br />
                
                <InputGroup>
                    <Cleave                        
                        id={name}
                        name={name}
                        type={inputType}
                        className='form-control'
                        placeholder={getPlaceHolder()}
                        onChange={onChange}
                        value={(formState[name]) ? (formState[name]) : ''}
                        error={error}
                        style={showCleaveBorder(name)}
                        disabled={props.disabled}

                        options={{
                            date: true,
                            datePattern: ['d', 'm', 'Y'],
                            delimiter: '.'
                        }}
                        autoComplete="off"
                    
                    />
                    <Picker                        
                        id={'p'+ name}
                        name={name}
                        onChange={onChange}
                        formState={formState}
                        value={(formState[name]) ? (formState[name]) : ''}                
                    />    
                </InputGroup>             

                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;                
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }
    else if (inputType === 'cleavePhone') {

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel><br />
                <Cleave
                    id={name}
                    name={name}
                    type={inputType}
                    className='form-control'
                    placeholder={getPlaceHolder()}
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showCleaveBorder(name)}
                    disabled={props.disabled}
                    autoComplete="none"

                    options={{
                        phone: true,
                        phoneRegionCode: 'NO'
                    }}

                />
                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }
    else if (inputType === 'cleaveAmount') {

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel><br />
                <Cleave
                    id={name}
                    name={name}
                    type={inputType}
                    className='form-control'
                    placeholder={getPlaceHolder()}
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showCleaveBorder(name)}
                    disabled={props.disabled}
                    autocomplete="off"

                    options={{
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        delimiter: ' ',
                        numeralDecimalMark: ','
                    }}

                />
                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }

    else if (inputType === 'cleaveOrgNr') {

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel><br />
                <Cleave
                    id={name}
                    name={name}
                    type={inputType}
                    className='form-control'
                    placeholder={getPlaceHolder()}
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showCleaveBorder(name)}
                    disabled={props.disabled}
                    autoComplete="off"

                    options={{
                        numericOnly: true,
                        blocks: [3, 3, 3],
                    }}

                />
                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }

    else if (inputType === 'cleaveFnrNr') {

        return (
            <Form.Group>
                <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel><br />
                <Cleave
                    id={name}
                    name={name}
                    type={inputType}
                    className='form-control'
                    placeholder={getPlaceHolder()}
                    onChange={onChange}
                    value={(formState[name]) ? (formState[name]) : ''}
                    error={error}
                    style={showCleaveBorder(name)}
                    disabled={props.disabled}
                    autoComplete="off"

                    options={{
                        numericOnly: true,
                        blocks: [6, 5],
                    }}

                />
                <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
                <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>
            </Form.Group>
        )
    }

    return (
        <Form.Group>
            <BfFormLabel htmlFor={name}>{messages[name + "Label"]}</BfFormLabel>
            <BfFormControl
                id={name}
                name={name}
                type={inputType}
                placeholder={getPlaceHolder()}
                onChange={onChange}
                onBlur={onBlur}
                value={(formState[name]) ? (formState[name]) : ''}
                error={error}
                style={showBorder(name)}
                disabled={props.disabled}
                autoComplete="off"
                maxLength={props.maxLength}
            />
            <BfFormLabelSm>{messages[name + "Small"]}</BfFormLabelSm>&nbsp;
            <BfFormErrorSm>{errorMessages[name]}</BfFormErrorSm>            
        </Form.Group>
    );
};

FormInput.defaultProps = {
    type: "text"
};

FormInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    inputType: PropTypes.oneOf(["text", "number", "password", "select", "textarea", "cleaveDate", "cleavePhone", "cleaveAmount", "cleaveOrgNr", "cleaveFnrNr"]),
    //value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
};
