import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'
import colorConfig from '../../colorConfig';

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: ${colorConfig.tabellrad_tekst} !important;

    font-family: "Fellix", "sans-serif";   
    font-weight: 600 !important; 
    
    background-color: 'transparent';

    :hover {
        text-decoration: underline;
    }
`

export const StyledPropotionalNavLink = styled(NavLink)`
    text-decoration: none !important;
    //color: ${colorConfig.tabellrad_tekst} !important;   
    color: rgb(0,62,96) !important; 
    background-color: 'transparent' !important;
    
    font-feature-settings: 'tnum' !important;
    font-variant-numeric: 'tabular-nums' !important;
    
    font-weight: 600 !important;
    font-family: "Fira Mono" !important;
    font-kerning: normal !important;
    letter-spacing: 2px;

    :hover {
        text-decoration: underline !important;
    }
`



export const StyledLink = styled(Link)`
    text-decoration: none;
    //color: ${colorConfig.tabellrad_tekst};
    color: rgb(0,62,96) !important; 
    background-color: 'transparent';
    
    font-family: "Fellix", "sans-serif";   
    font-weight: 600 !important; 
    font-size: 20px !important;

    :hover {
      text-decoration: underline !important;
    }
`

// Benyttes i sakslisten
export const StyledKfTable = styled.table`   
  font-size: 20px;
  width: 100%;
  background-color: ${colorConfig.lys_gul}; 
  
  @media print {    
      table { font-size: 80%; }
  }

  tr:hover {
    //opacity: 0.5;
    cursor: default;

  }
  tr:nth-child(odd) { 
    background-color: ${colorConfig.taballrad_mork};  
    color: ${colorConfig.tabellrad_tekst} !important;
    @media print {
      * { 
          color: black;
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif;    
        }
      }    
      :hover {
        color: black;
        background-color: ${colorConfig.button_bg};
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }
  }
  tr:nth-child(even) {       
    background-color: ${colorConfig.tabell_lysGul}; 
    color: ${colorConfig.tabellrad_tekst} !important;
    @media print {
      * { 
          color: black;
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif;          
        }
      }
    :hover {
        color: black;
        background-color: Tan;
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }       
  }
  th {    
    white-space: nowrap;
    line-height: 38px;  
    padding: 5px 10px 20px 10px; 
    font-family: "Fellix", "sans-serif";   
    font-weight:500;
    font-size: 20px;   
    background-color: ${colorConfig.tabell_lysGul};
    color: ${colorConfig.header_tekst}; 
    
  }
  td {
    padding: 12px 10px 8px 10px; 
    font-family: "Fellix", "sans-serif";    
    font-weight: 400;
    word-wrap: break-word;   
    overflow-wrap:break-word;
    word-break:break-all;
    white-space: pre-wrap;
    vertical-align: top;  
  }
  td:hover {
    cursor: default;
  }  


`

// Benyttes i oversikt og et par andre
export const StyledKfTextTable = styled.table`   
  width: 100%;
  border-spacing: 10;
  vertical-align: top;
  line-height: 23px; 
    
  tr:hover {    
    cursor: default;
  }
  tr:nth-child(odd) {       
      background-color: ${colorConfig.taballrad_mork};  
      color: ${colorConfig.tabellrad_tekst} !important;
      @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }      
      :hover {
        color: black;    
        background-color: ${colorConfig.button_bg};
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }
  }
  tr:nth-child(even) {              
    background-color: ${colorConfig.tabell_lysGul}; 
    color: ${colorConfig.tabellrad_tekst} !important;
    @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }  
    :hover {
        color: black;    
        background-color: Tan;
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }   
  }
  th {
    line-height: 38px;  
    padding: 5px 10px 20px 10px; 
    font-family: "Fellix", "sans-serif";   
    font-weight:500;
    font-size: 20px;   
    background-color: ${colorConfig.tabell_lysGul};
    color: ${colorConfig.header_tekst};
    @media print {
      * {          
          font-weight: 800 !important;
          padding: 0px 0px 0px 0px !important;
        }
      } 
  }
  td {    
    padding: 12px 10px 8px 10px; 
    font-family: "Fellix", "sans-serif";    
    font-weight: 400;
    word-wrap: break-word;   
    overflow-wrap:break-word;
    word-break:break-all;
    white-space: pre-wrap;
    vertical-align: top; 
  }
  td:hover {
    cursor: default;
  }
  tfoot {
    @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }  
  }  
`

export const StyleInlineFkTable = styled(StyledKfTextTable)`
margin-top: 28px;
margin-bottom: 20px;
border: 2px solid #dee2e6;

tr:hover {
    opacity: 1;
    font-weight: 800;
  }
th {   
    background-color: white;
    border-bottom: 2px solid #dee2e6;
    border-top: 2px solid #dee2e6;
    color: ${colorConfig.header_tekst}; 
    font-family: "Fellix", "sans-serif"; 
    font-weight: 500;
  }
td {    
    font-family: "Fellix", "sans-serif"; 
    font-weight: 500;    
    color: ${colorConfig.tabellrad_tekst};  
  }
tr:nth-child(odd) {
    background-color:white;
}
tr:nth-child(even) {
      background-color: white;
}
`

export const CellWordWrap = styled.div`
  word-wrap:break-word; 
  overflow-wrap:break-word;
`

// Benyttes i stengeliste
export const StyledExpandRowTextTable = styled.table`   
  width: 100%;
  border-spacing: 10;
  vertical-align: top;
  line-height: 23px; 
    
  tr:hover {    
    cursor: default;
  }
  tr:nth-child(odd) {       
      background-color: ${colorConfig.taballrad_mork};  
      color: ${colorConfig.tabellrad_tekst} !important;
      @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }      
      :hover {
        color: black;    
        background-color: ${colorConfig.button_bg};
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }
  }
  tr:nth-child(even) {              
    background-color: ${colorConfig.tabell_lysGul}; 
    color: ${colorConfig.tabellrad_tekst} !important;
    @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }  
    :hover {
        color: black;    
        background-color: Tan;
        -webkit-transition: background-color .5s ease-out;
        -moz-transition: background-color .5s ease-out;
        -o-transition: background-color .5s ease-out;
        transition: background-color .5s ease-out;
        transition-delay:0.1s;
      }   
  }
  th {
    line-height: 38px;  
    padding: 5px 10px 20px 10px; 
    font-family: "Fellix", "sans-serif";   
    font-weight:500;
    font-size: 20px;   
    background-color: ${colorConfig.tabell_lysGul};
    color: ${colorConfig.header_tekst};
    @media print {
      * {          
          font-weight: 800 !important;
          padding: 0px 0px 0px 0px !important;
        }
      } 
  }
  td {    
    padding: 12px 10px 8px 10px; 
    font-family: "Fellix", "sans-serif";    
    font-weight: 400;
    word-wrap: break-word;   
    overflow-wrap:break-word;
    word-break:break-all;
    white-space: pre-wrap;
    vertical-align: top; 
  }
  td:hover {
    cursor: default;
  }
  tfoot {
    @media print {
      * { 
          color: black; 
          background-color: white;  
          line-height: 15px !important;
          font-size: 12px;
          padding: 0px 0px 0px 0px !important;
          font-family: 'Roboto', sans-serif; 
        }
      }  
  }
  .expanded-table {
    width: 80%;
    border-spacing: 5px; 
    background-color: inherit;
    pointer-event: none;

    th {
      line-height: 30px;  
      padding: 5px 10px 20px 10px; 
      font-family: "Fellix", "sans-serif";   
      font-weight:500;
      font-size: 18px;
      background-color: inherit;   
      color: ${colorConfig.header_tekst};
      @media print {
        * {          
            font-weight: 800 !important;
            padding: 0px 0px 0px 0px !important;
          }
        } 
    }

    tr:nth-child(odd) {
      background-color: inherit;  
      color: ${colorConfig.tabellrad_tekst} !important;
    }

    tr:nth-child(even) {
      background-color: inherit;  
      color: ${colorConfig.tabellrad_tekst} !important;
    }
  }

  @keyframes expandRow {
    0% { height: 0; opacity: 0; }
    100% { height: auto; opacity: 1; }
  }
  
  @keyframes minimizeRow {
    0% { height: auto; opacity: 1; }
    100% { height: 0; opacity: 0; }
  }

  .expand-animation {
    animation: expandRow 0.3s forwards;
  }
  
  .minimize-animation {
    animation: minimizeRow 0.3s forwards;
  }
`