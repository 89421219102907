import React, { useState, useMemo, useEffect } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './NewCaseContainer.i18n'

import DebitorList from './debitorList/DebitorList';
import { Alert } from 'react-bootstrap';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import UseTableSorter from '../../components/useTable/UseTable';
import usePaginator from '../../components/useTable/UsePaginator';
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import DebitorForm from './debitorForm/DebitorForm';
import FakturaForm from './fakturaForm/FakturaForm';
import Receipt from './bekreftForm/Receipt';
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { setDebitor, displayForms } from '../../reduxStore/newCase/newCaseActions';
import { getCMSbyIndex, getAhlinCMSbyId } from '../../reduxStore/HeadlessCMS/headlessCmsActions';

const NewCaseContainer = () => {

  const showToasts = true
  const locale = React.useContext(LocaleContext);
  const localeMessages = messages[locale]

  // const cmsArticleId = '10174'
  const articleId = 'ny_sak'
  const state = useSelector(state => state.newCase)
  const cmsArticlesState = useSelector(state => state.headlessCMS)
  // nb: Felles kreditorliste med sakslisteheader
  const crState = useSelector(state => state.sakslisteHeader)
  const _dispatch = useDispatch()
  
  useEffect(() => {
    // Ikke hent dersom det er unødvendig, da risikerer du å refreshe token
    // når idle logout modal vises, og auto logoff vil ikke fungere
    let fetchCMS = (!cmsArticlesState.data[articleId])
    if (fetchCMS) {
      _dispatch(getAhlinCMSbyId(articleId, showToasts, localeMessages))
    }
  }, [])

  // State for grid sorting
  const [currentSeq, setCurrentSeq] = useState('up'); // default
  const [currentField, setCurrentField] = useState('debtorName');    // feltnavn
  const [currentType, setCurrentType] = useState('string'); // num
  const [sortClicked, setSortClicked] = useState(0)

  // state for grid paginator
  const [numberPrPage, setNumberPrPAge] = useState(10)
  const [activePage, setActivePage] = useState(1);

  const [masterT1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
    currentSeq, setCurrentSeq,
    currentField, setCurrentField,
    currentType, setCurrentType
  )

  // Nødvendig pga henting av memoised felt
  const t1ChangeSortField = (fieldName, fieldType) => {
    masterT1ChangeSortField(fieldName, fieldType)

    // Sett trigger for å tvinge memoized function til rerender
    setSortClicked(Date.now())
  }

  const memoizedCasesForTable = useMemo(
    () => {
      //console.log('memoizedCasesForTable', sortClicked)

      if (state.debitorListFiltered.length > 0) {
        return [...state.debitorListFiltered].sort(t1GetSortFunc().fn)
      }
      else {
        return []
      }

    },
    [state.debitorListFiltered, sortClicked]
  );

  const getCasesForTable = () => {
    // Gir bedre ytelse ettersom denne kan bli kalt for mange ganger
    return memoizedCasesForTable
  }

  const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
    getCasesForTable(),
    numberPrPage, setNumberPrPAge,
    activePage, setActivePage
  )

  const selectDebitor = (debitor) => {
    _dispatch(setDebitor(debitor))
  }

  const getInfo = (htmlContent) => {
    if (htmlContent) {
      return (
        <div>
          {htmlContent.map((item) => (
            <div key={item.id}>
              {/* <h1>{localeMessages.Header1}</h1> */}
              <h2 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h2>
              <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
            </div>
          ))}
        </div>
      )
    }
    return null;
  }

  const showDebitorTable = () => {

    if (_.isEmpty(crState.kreditorliste)) {
      return (
        <>
          <Alert variant='danger'>{localeMessages['kreditorNoSel']}</Alert>

          <ArticleArea>
            {getInfo(cmsArticlesState.data[articleId] || '')}
            <br />
          </ArticleArea>
        </>
      )
    }
    else if ((!state.kreditorId) && (crState.kreditorliste.length > 1)) {
      return (
        <>
          <Alert variant='danger'>{localeMessages['kreditorNoSel']}</Alert>

          <ArticleArea>
            {getInfo(cmsArticlesState.data[articleId] || '')}
            <br />
          </ArticleArea>
        </>
      )
    }
    else if (state.debitorListFiltered.length === 0) {
      return (
        <>
          <ArticleArea>
            <Alert variant='danger'>{localeMessages['filterNone']}</Alert>

            {getInfo(cmsArticlesState.data[articleId] || '')}
            <br />
          </ArticleArea>
        </>
      )
    }
    else {
      return (
        <DebitorList
          localeMessages={localeMessages}
          PaginationDropdown={PaginationDropdown}
          t1IndicaterIcon={t1IndicaterIcon}
          t1ChangeSortField={t1ChangeSortField}
          Paginate={Paginate}
          PaginationIndex={PaginationIndex}
          PaginationNav={PaginationNav}
          selectDebitor={selectDebitor}
        />
      )
    }
  }

  const showEditDebitorForm = () => {
    return (
      <DebitorForm
        state={state}
        dispatch={_dispatch}
        showToast={showToasts}
        localeMessages={localeMessages}
        disabled={state.existingDebitor}
      />
    )
  }

  const showVisFakturaForm = () => {
    return (
      <FakturaForm
        state={state}
        dispatch={_dispatch}
        showToast={showToasts}
        localeMessages={localeMessages}
      />
    )
  }

  const showReceipt = () => {
    //console.log('Vis kvittering')
    return (
      <Receipt
        messages={localeMessages}
        state={state}
      />
    )
  }

  if (state.error) {
    return (<Alert variant='danger'>{state.error}</Alert>)
  }

  return (
    <>

      {(state.loading || crState.loading || cmsArticlesState.loading) && <OverlaySpinner></OverlaySpinner>}

      {!state.loading && (state.displayForm === displayForms.DEBITORFILTER) && showDebitorTable()}
      {(!state.loading && (state.displayForm === displayForms.EDITDEBITOR)) && showEditDebitorForm()}
      {!state.loading && (state.displayForm === displayForms.FAKTURAUPLOAD) && showVisFakturaForm()}
      {!state.loading && (state.displayForm === displayForms.FAKTURAUPLOADEDIT) && showVisFakturaForm()}
      {!state.loading && (state.displayForm === displayForms.RECEIPT) && showReceipt()}

    </>
  )
}

export default NewCaseContainer
