export const messages = {
    nb: {

        GHF_velg: 'Velg',
        GHF_invoiceDate: 'Fakturadato',
        GHF_dueDate: 'Forfallsdato',
        GHF_product: 'Produkt',
        GHF_debitorId: 'Kundenr',
        GHF_debitorName: 'Kundenavn',
        GHF_invoiceNumber: 'Fakturanummer',
        GHF_invoiceText: 'Fakturatekst',
        GHF_invoiceAmount: 'Beløp',

        MerkAlle: 'Merk alle',
        MerkIngen: 'Merk ingen',
        Send: 'Send',

        antall: 'Antall fakturaer',
        sum_alle: 'Sum alle fakturaer',
        antall_valgte: 'Antall valgte',
        sum_valgte: 'Sum valgte',
        antall_ikke_valgte: 'Antall ikke valgte',
        sum_ikke_valgte: 'Sum ikke valgte',

        BE_Header: 'Bekreft innsending av fakturaer',
        BE_Ja: 'Fortsett',
        BE_Nei: 'Avbryt',

        VelgKreditor: 'For å hente fakturaer, må du velge en kreditor fra nedtrekkslisten oppe til høyre.',
        ingenFakturaer: 'Det er ingen fakturer som kan sendes inn for denne kreditor, ',
        IkkeFakturaAdm: 'Denne kreditoren har ikke fakturaadministrasjon. ',
   
        toastHentFakturalisteFailed: 'En feil oppstod under henting av fakturaer: ',
        toastHentFakturalisteOk: 'Fakturaliste er hentet. ',

        toastSendFakturaliste: 'Valgte fakturaer er sendt til fakturaadm. ',
        toastSendFakturalisteOk: 'Innsendte fakturaer er godkjent innsendt. ',
        toastSendFakturalisteFailed: 'Innsending av fakturaer feilet. ',

        faHentetFor: ', fakturaer hentet for siste',
        faIngenFaktRet: 'Ingen fakturaer returnert',
        faIncInterval: 'Du kan eventuelt prøve øke intervallet dersom du har eldre fakturaer.',
        faSlow: 'Vennligst vent, henter data fra regnskapssystem, det kan ta litt tid....'
        

    }
    
} 