import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../../Styling/FormComponents/FormInput'
import { BfYellowRowForm, BfTightYellowRowForm, BfButtonBlue, BfRowSpacer, YellowRoundedContainer } from '../../../Styling/styledForm/styledBlueForm'
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import useDebitorForm from './useDebitorForm';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from '../../../components/confirmModal/ConfirmModal';



const DebitorForm = (props) => {

    const {
        formValues, formValidators, handleChange, handleBlur, formState, validateAll,
            hasErrors, errorMessages, extraErrors, setExtraErrors,
            submitForm, errorMessage, localeMessages, produktOptions,
            debtorOptions, debtorTypeOptions, countryOptions, handlePnrChange,
            newDebtorCheckReference, existingDebtorCheckReference, debtorWithReference,
            confirmModalVisible, confirmModalOk, confirmModalCancel, altDebitor
    } = useDebitorForm(props.state, props.dispatch, props.showToasts, props.localeMessages)
    
    //const localeMessages = props.messages
    //console.log(errorMessages, extraErrors)
    const history = useHistory();

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
                onBlur={handleBlur}
            />
        )
    }

    const getFormFieldPnr = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handlePnrChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    const pStyle = {
        width: '80%'        
    }

    const disableExisting = () => {
        return true;
    }

    const getPrivateRow = (disabled) => {

        if (disabled) {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}
                    
                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }
        else {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}
                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }
        
    }

    const getNaeringRowRow = () => {
        return (
            <>
                {getFormField(6, 'debtorName', null, null, props.disabled, 40)}
                {getFormField(2, 'debtorIdentificationNumber', 'cleaveOrgNr', null, props.disabled)}
                {formState.debtorNo &&
                    getFormField(2, 'debtorNo', null, null, props.disabled)}

            </>
        )
    }

    const showConfirmModal = () => {
        const setHeader = `${localeMessages.md_Header1}: ${altDebitor.firstName} ${altDebitor.lastName}`       
        return (
            <ConfirmModal 
                okHandler={confirmModalOk}
                cancelHandler={confirmModalCancel}
                message={localeMessages.md_Message}
                header={setHeader}
                okCaption={localeMessages.md_Ok}
                cancelCaption={localeMessages.md_Cancel}
                data={altDebitor}
            >
            </ConfirmModal>  
        )
    }

    return (
                          
 
       <Container fluid style={pStyle}>

            {confirmModalVisible && showConfirmModal()}
            
            <ArticleArea>
                <h1>{localeMessages.SK_Head}</h1>
                <p>{localeMessages.SK_Caption}</p>
                
            </ArticleArea>

            <Form autocomplete="chrome-off">
                <YellowRoundedContainer fluid  style={{padding: '15px'}}>

                                             
                    {hasErrors && (
                        <Alert fluid variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    <BfYellowRowForm>
                        {getFormField(4, 'produktType', 'select', produktOptions)}
                        {getFormField(8, 'referanseNummer', null, null, false, 32)}
                    </BfYellowRowForm>

                    </YellowRoundedContainer>
                    <BfRowSpacer>

                    </BfRowSpacer>
                    
                    <YellowRoundedContainer fluid  style={{padding: '15px'}}>
                        

                    <BfYellowRowForm>
                        {getFormField(2, 'debtorType', 'select', debtorTypeOptions, props.disabled)}                        

                        {formState.debtorType === 'Næring' && 
                            getNaeringRowRow()
                        }
                        {formState.debtorType === 'Privat' &&
                            getPrivateRow(props.disabled)
                        }
                        {formState.debtorType === '' &&
                            getPrivateRow(true)
                        }

                        
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(4, 'postalAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'streetAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'email', null, null, props.disabled, 63)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormFieldPnr(2, 'postalCode', null, null, props.disabled, 10)}                        
                        {getFormField(4, 'postalPlace', null, null, props.disabled, 30)}
                        {getFormField(2, 'countryCode', 'select', countryOptions, props.disabled)}

                        {getFormField(3, 'phone', null, null, props.disabled, 11)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'caseMessage', 'textarea')}                                    
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                {localeMessages.Lagre}
                            </BfButtonBlue>

                            &nbsp;

                            <BfButtonBlue variant="primary" type="button" onClick={() => history.push("/CaseNew/init")}>
                                {localeMessages.butTilbake}
                            </BfButtonBlue>
                            

                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </YellowRoundedContainer>
            </Form>            
        </Container>
    )
}

export default DebitorForm

