import React, { useContext, useEffect, useState, useRef } from 'react';
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.no";
import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { messages } from './EditProfile.i18n'

import { ArticleArea } from '../../../Styling/Layout/ContentArea'
import { Container, Row, Col, Button, Form, InputGroup, FormControl, Alert } from "react-bootstrap";
import { BfRowForm, BfTightRowForm, BfRowSpacer, BfFormLabel, BfFormLabelSm, BfFormErrorSm, BfFormControl, BfButtonBlue, BfSelect, YellowRoundedContainer, BfYellowRowForm, BfTightYellowRowForm } from '../../../Styling/styledForm/styledBlueForm'
import { useUcForm } from '../../../hooks/useUcForm'
import { useAuth } from '../../../hooks/useAuth'
import { toast } from 'react-toastify';
import { Spinner } from '../../../components/spinner/Spinner'


import styled from 'styled-components'
//import useSelectTransfer from '../../../hooks/useSelectTransfer';
import useEditProfile from './useEditProfile';
import { GetFormValidators, foreningIsRequired, kreditorIsRequired } from './editProfileValidators'
import { BfColInput } from '../../../Styling/FormComponents/FormInput';
import BekreftSlett from './BekreftSlett';
import toolsMenuReducer from '../../../reduxStore/toolsMenu/toolsMenuReducer';

const EditProfile = (props) => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const state = props.state
    
    const { header, beskrivelse, EpostPlaceholder, NavnPlaceholder, PhoneNumberPlaceholder,
        PassordPlaceholder, Passord1Placeholder, Passord2Placeholder,
        bisnodeBrukerPlaceholder, bisnodePassordPlaceholder} = messages[locale]
    
    const auth = useAuth()

    // Array med rutiner for å validere felter mens man skriver
    const formValidators = GetFormValidators(localeMessages)

    const handleMfaCheckboxChange =(e)=> {
        if (!e.target.checked) {
            handleCheckboxChange(e)
        } else {
            e.target.checked = false;
        }
    }

    // Verdier i form settes i reducer og sendes i props inn i formen
    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid,
        validatorsState, clearField, clearErrors } = useUcForm(props.formValues, formValidators)

    const [repaint, forceRepaint] = useState()

    
    const { getRolleOption, getForeningsOption, FilteredForeningKreditorListe,
        FilteredKreditorListe, addNode, removeNode, debugInfo, hasErrors, extraErrors, submitForm,
        visBekreftSlett, slettCancelHandler, slettOkHandler, visBekreftModal } = useEditProfile(auth, props, formState, forceRepaint, localeMessages, errorMessages, validateAll, clearField, props.deleteUser)
    
    // ------------------------------------------------------------------------------------
    // start Rutiner for håndtering av valgte kreditorer til og fra 
    // ------------------------------------------------------------------------------------
    
    /*
    Note to future devs: Kreditorlistene er løst vha useRef, som gir direkte tilgang til dom
    der du lett kan hente både tekst og value, fordi onchange eventene bare ligger selected
    options på state.  Men kunne også vært laget ved å søke på state i collections og overført 
    på den måten.

    I tilleg muteres state ved å endre collections direkte, burde egenglig vært gjort via 
    reduceren. Men endres dersom buggy...
    */
    const KreditorListe = React.useRef()
    const valgtKreditorListe = React.useRef()
    //const selectTransfer = useSelectTransfer()
    const leggTilKreditor = () => addNode(KreditorListe.current, valgtKreditorListe.current)
    const trekkFraKreditor = () => removeNode(valgtKreditorListe.current)  


    // ------------------------------------------------------------------------------------
    // slutt Rutiner for håndtering av valgte kreditorer til og fra 
    // ------------------------------------------------------------------------------------

        
    useEffect(() => {
        getKreditorListe(formState.rolle, formState.forening)
    }, []);

    const getKreditorListe = (rolle, forening) => {
        // Hent kreditorlisten dersom vi har en forening
        if ((forening) && (rolle) && ((rolle === auth.accessLevels.CUSTOMERADMIN) || (rolle === auth.accessLevels.USER)))  {
            props.getKreditorListe(forening)
        }
    }

    // ------------------------------------------------------------------------------------
    // Når du endrer forening, må kreditorer tømmes, og kreditorer for foreningen hentes
    // Dette skulle kanskje vært cachet, så du bare laster hver forening en gang
    // Fix om tregt i prod
    // ------------------------------------------------------------------------------------

    const emptySelectedKreditors = () => setField('kreditorListe', [])

    const handleForeningChange = (e) => {
        console.log('ForeningChange')
        handleChange(e)

        // Hent kreditorliste for den nye foreningen             
        getKreditorListe(formState.rolle, e.target.value)   
    }

    const handleRoleChange = (e) => {
        console.log('handleRoleChange')
        handleChange(e)

        // Setter du den til Supervisor så finnes ikke foreningsbegrep, heller ikke dersom den er tom
        if ((e.target.value === auth.accessLevels.SUPERVISOR) || (e.target.value === ''))  {
            setField('forening', '')
            emptySelectedKreditors();            
            props.getKreditorListe('')
        } else if (e.target.value === auth.accessLevels.USERADMIN) {
            emptySelectedKreditors();            
            props.getKreditorListe('')
        }     
        else {
            if (formState.kreditorListe.length === 0) {
               getKreditorListe(e.target.value, formState.forening) 
            }
        }        
    }
    
    const kopierDisable = () => {
        if ((formState.newUser === false) && (formState.orgUsername))
            return false
        return true
    }

    // Kun supervisoerer skal se denne knappen,
    // og det må være lastet en kunde
    const slettDisable = () => {

        // Utgangspunktet disable 
        let result = false;
        
        // Sjekk at du har lastet en kund
        if (!formState.orgUsername) {
            return result
        }

        // Du kan ikke slette deg selv
        if (auth.user.email === formState.orgUsername) {
            return result
        }

        // Nivå 1
        if (auth.isSupervisor()) {
            // Kan slette alltid
            result = true;
        }
        // Nivå 2
        else if (auth.isForeningSupervisor()) {
            if (formState.rolle === auth.accessLevels.CUSTOMERADMIN)
                result = true;
            if (formState.rolle === auth.accessLevels.USER)
                result = true;            
        }
        // Nivå 3
        else if (auth.isAdminKreditor()) {
            if (formState.rolle === auth.accessLevels.USER)
                result = true;
        }
        // Nivå 4, du kan ikke slette noen
        else if (auth.isUser()) {
            result = false;
        }  

        return result
    }

    const webserviceBrukerDisable = () => {
        if (auth.isSupervisor) {            
            return false
        }

        if (auth.isForeningSupervisor) {
            return false
        }

        return true
    }
    
    const kreditorSelectorsDisable = () => {
        // En supervisor skal heller aldri kunne håndtere kreditorer
        if (formState.rolle === auth.accessLevels.SUPERVISOR) {
            return true
        }
        // Dersom du ikke har valgt forening så skal denne være tom
        if (!formState.forening) {
            return true
        }

        return false
    }

    const foreningDisable = () => {
        if ((formState.rolle === auth.accessLevels.SUPERVISOR) || (formState.rolle === '')) {
            return true
        }
        return false
    }
   
    const setCurrentUserIsCopyOf = props.setCurrentUserIsCopyOf 
    const copyCurrentUser = () => {
        setField("orgUsername", "")
        setField("username", "")
        setField("fullName", "")
        setField("newPassword1", "")
        setField("newPassword2", "")
        setField("phoneNumber", "")
        setField("twoFactorEnabled", false)
        setField("newUser", true)
        clearErrors()
    }

    const toastNotImplemented = () => {
        toast.info('Ikke implementert ennå....')
    }

    const showBorder = (fieldName) => {
        let allErrors = {...errorMessages, ...extraErrors }
        let error = allErrors[fieldName]
        if (!error) 
            return {}
        
        return { border: '2px solid red' }
    }

    const showBorderSelect = (fieldName) => {
        let allErrors = {...errorMessages, ...extraErrors }
        let error = allErrors[fieldName]
        if (!error) 
            return {
                height: '270px'
        } 

        return { 
            border: '2px solid red', 
            height: '270px'
        }
    }

    const showCleaveBorder = (fieldName) => {
        let allErrors = { ...errorMessages, ...extraErrors }
        let error = allErrors[fieldName]
        if (!error)
            return {
                fontFamily: "Fellix, sans-serif",
                fontSize: '20px',
                border: "0px solid #ced4da",
                height: "calc(1.5em + .75rem + 2px)",
                padding: ".375rem .75rem",
                borderRadius: "0.5em"
            }
            
        return {
            fontFamily: "Fellix, sans-serif",
            fontSize: '20px',
            height: "calc(1.5em + .75rem + 2px)",
            padding: ".375rem .75rem",
            borderRadius: "0.5em",
            border: '2px solid #842029'
        }
    }


    if (props.loading) {
        return (<Spinner />)
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            
            {visBekreftSlett && <BekreftSlett
                cancelHandler={slettCancelHandler}
                okHandler={slettOkHandler}
                gotoSearchResult={props.gotoSearchResult}
                messages={localeMessages}
            />}

            <Container>
                <ArticleArea>
                    <h1>{header}</h1>
                    <p>{beskrivelse}</p>
                </ArticleArea>           
            </Container>

            <Form autoComplete='false'>
                <YellowRoundedContainer fluid="md" style={{padding: '15px'}}>

                    {hasErrors && (
                        <Alert variant='danger'>
                            Du har felter som ikke er korrekt utfylt, korriger dette før du kan!
                        </Alert>                       
                    )}

                    {props.state.savedMessage && (
                        <Alert variant='success'>
                            {props.state.savedMessage}
                        </Alert>                       
                    )}
                    
                    <BfYellowRowForm>

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formEpost">
                                <BfFormLabel><FormattedMessage id="EpostLabel" /></BfFormLabel>
                                <BfFormControl
                                    type="input"
                                    placeholder={EpostPlaceholder} 
                                    name="username"
                                    value={formState.username}
                                    onChange={handleChange}
                                    style={showBorder('username')}
                                />
                                <BfFormLabelSm><FormattedMessage id="EpostSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['username']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                        <Col xs={12} s={12} md={6}>
                            <Form.Group controlId="formNavn">
                                <BfFormLabel><FormattedMessage id="NavnLabel" /></BfFormLabel>
                                <BfFormControl
                                    type="text"
                                    placeholder={NavnPlaceholder}
                                    name="fullName"
                                    value={formState.fullName || ''}
                                    onChange={handleChange}
                                    style={showBorder('fullName')}
                                    />
                                <BfFormLabelSm><FormattedMessage id="NavnSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['fullName']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                    </BfYellowRowForm>                  

                    <BfYellowRowForm>                       

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formPassword1">
                                <BfFormLabel><FormattedMessage id="Passord1Label" /></BfFormLabel>
                                <BfFormControl
                                    autoComplete="new-password"
                                    type="password"
                                    placeholder={Passord1Placeholder} 
                                    name="newPassword1"
                                    value={formState.newPassword1}
                                    onChange={handleChange}
                                    style={showBorder('newPassword1')}                                    
                                />
                                <BfFormLabelSm><FormattedMessage id="Passord1Small" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['newPassword1']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formPassword2">
                                <BfFormLabel><FormattedMessage id="Passord2Label" /></BfFormLabel>
                                <BfFormControl
                                    autoComplete="new-password"
                                    type="password"
                                    placeholder={Passord2Placeholder}
                                    name="newPassword2"
                                    value={formState.newPassword2}
                                    onChange={handleChange}   
                                    style={showBorder('newPassword2')}
                                />
                                <BfFormLabelSm><FormattedMessage id="Passord2Small" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['newPassword2']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                    </BfYellowRowForm>
                    
                    <BfYellowRowForm>

                        <Col xs={12} md={6}>
                            <Form.Group controlId="formPhoneNumber">
                                <BfFormLabel><FormattedMessage id="PhoneNumberLabel" /></BfFormLabel><br />
                                <BfFormControl
                                    type="text"
                                    placeholder={PhoneNumberPlaceholder}
                                    name="phoneNumber"
                                    value={formState.phoneNumber || ''}
                                    onChange={handleChange}
                                    style={showBorder('phoneNumber')}
                                />
                                {/*
                                <Cleave
                                    id='phoneNumber'
                                    name="phoneNumber"
                                    type="text"
                                    placeholder={PhoneNumberPlaceholder}
                                    onChange={handleChange}
                                    value={formState.phoneNumber || ''}
                                    style={showCleaveBorder('phoneNumber')}
                                    className="form-control"

                                    options={{
                                        phone: true,
                                        phoneRegionCode: 'NO'
                                    }}

                                />
                                */}
                                <BfFormLabelSm><FormattedMessage id="PhoneNumberSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['phoneNumber']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={2}>
                            <Form.Group controlId="formBisnodeEnabled">
                              <BfFormLabel></BfFormLabel>
                                <Form.Check
                                    type="checkbox"
                                    label={<FormattedMessage id="bisnodeEnabledLabel" />} 
                                    name="bisNodeEnabled"
                                    checked={formState.bisNodeEnabled}
                                    onChange={handleCheckboxChange}
                                    style={showBorder('bisNodeEnabled')}
                                    />
                              <BfFormLabelSm><FormattedMessage id="bisnodeEnabledSmall" /></BfFormLabelSm>
                            </Form.Group>                                                       
                        </Col>

                        <Col xs={12} md={2}>
                            <Form.Group controlId="webservicebruker">
                              <BfFormLabel></BfFormLabel>
                                <Form.Check
                                    disabled={webserviceBrukerDisable()}
                                    type="checkbox"
                                    label={<FormattedMessage id="webservicebrukerLabel" />} 
                                    name="webserviceBruker"
                                    checked={formState.webserviceBruker}
                                    onChange={handleCheckboxChange}
                                    style={showBorder('webserviceBruker')}
                                    />
                              <BfFormLabelSm><FormattedMessage id="webservicebrukerSmall" /></BfFormLabelSm>
                            </Form.Group>                                                       
                        </Col>

                        <Col xs={12} md={2}>
                            <Form.Group controlId="formDisabled">
                                <BfFormLabel></BfFormLabel>
                                <Form.Check
                                    type="checkbox"
                                    label={<FormattedMessage id="disabledLabel" />}
                                    name="disabled"
                                    checked={formState.disabled}
                                    onChange={handleCheckboxChange}
                                    style={showBorder('disabled')}
                                />
                                <BfFormLabelSm><FormattedMessage id="disabledSmall" /></BfFormLabelSm>
                            </Form.Group>
                        </Col>


                    </BfYellowRowForm>

                    {formState.bisNodeEnabled &&
                        <BfYellowRowForm>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBisnodeBruker">
                                    <BfFormLabel><FormattedMessage id="bisnodeBrukerLabel" /></BfFormLabel>
                                    <BfFormControl
                                        disabled={!formState.bisNodeEnabled}
                                        type="text"
                                        placeholder={bisnodeBrukerPlaceholder}
                                        name="bisNodeUser"
                                        value={formState.bisNodeUser}
                                        onChange={handleChange}
                                        style={showBorder('bisNodeUser')}
                                    />
                                    <BfFormErrorSm>{errorMessages['bisNodeUser']}</BfFormErrorSm>
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6}>
                                <Form.Group controlId="formBisnodePassord">
                                    <BfFormLabel><FormattedMessage id="bisnodePassordLabel" /></BfFormLabel>
                                    <BfFormControl
                                        disabled={!formState.bisNodeEnabled}
                                        type="password"
                                        placeholder={bisnodePassordPlaceholder}
                                        name="bisNodePassword"
                                        value={formState.bisNodePassword}
                                        onChange={handleChange}
                                        style={showBorder('bisNodePassword')}
                                    />
                                    <BfFormErrorSm>{errorMessages['bisNodePassword']}</BfFormErrorSm>
                                </Form.Group>
                            </Col>
                        </BfYellowRowForm>
                    }
                
                    <BfYellowRowForm>


                        <Col xs={12} md={6}>
                            <Form.Group controlId="formRolle">
                                <BfFormLabel><FormattedMessage id="RolleLabel" /></BfFormLabel>

                                <BfSelect
                                    name="rolle"
                                    value={formState.rolle}
                                    onChange={handleRoleChange}
                                    style={showBorder('rolle')}
                                >
                                    {getRolleOption()}                                    

                                </BfSelect>

                                <BfFormLabelSm><FormattedMessage id="RolleSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{errorMessages['rolle']}</BfFormErrorSm>
                            </Form.Group>

                        </Col>

                        <Col xs={12} md={3}>
                            <Form.Group controlId="formForening">
                                <BfFormLabel><FormattedMessage id="ForeningLabel" /></BfFormLabel>

                                <BfSelect
                                    disabled={foreningDisable()}                                    
                                    as="select"
                                    name="forening"
                                    value={formState.forening}
                                    onChange={handleForeningChange}
                                    style={showBorder('forening')}
                                >
                                    {getForeningsOption()}                                    
                                </BfSelect>

                                <BfFormLabelSm><FormattedMessage id="ForeningSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{extraErrors['forening']}</BfFormErrorSm>
                            </Form.Group>
                        </Col>

                        <Col xs={12} md={3}>
                            <Form.Group controlId="twoFactorEnabled">
                              <BfFormLabel><FormattedMessage id="twoFactorEnabledHeaderLabel" /></BfFormLabel>
                                <Form.Check
                                    disabled={!formState.twoFactorEnabled}
                                    type="checkbox"
                                    label={<FormattedMessage id="twoFactorEnabledLabel" />} 
                                    name="twoFactorEnabled"
                                    checked={formState.twoFactorEnabled}
                                    onChange={handleMfaCheckboxChange}
                                    style={showBorder('twoFactorEnabled')}
                                />
                              <BfFormLabelSm><FormattedMessage id="twoFactorEnabledSmall" /></BfFormLabelSm>
                            </Form.Group>                                                       
                        </Col>
                                            
                    </BfYellowRowForm>

                    </YellowRoundedContainer>

                    <BfRowSpacer>

                </BfRowSpacer>
                
                    <YellowRoundedContainer fluid="md" style={{padding: '15px'}}>


                    <BfTightYellowRowForm>

                        <Col xs={12} md={5}>
                            <InputGroup size="sm" className="mb-3">

                                <FormControl
                                    disabled={kreditorSelectorsDisable()}
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="sokKreditor"
                                    value={formState.sokKreditor}
                                    onChange={handleChange}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" disabled>filter</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                        <Col xs={6} md={1}>
                        </Col>
                        <Col xs={6} md={1}>
                        </Col>

                        <Col xs={12} md={5}>
                            <InputGroup size="sm" className="mb-3">
                                <FormControl
                                    disabled={kreditorSelectorsDisable()}
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    name="filterKreditor"
                                    value={formState.filterKreditor}
                                    onChange={handleChange}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" disabled>filter</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>

                    </BfTightYellowRowForm>

                    <BfYellowRowForm>

                        <Col xs={12} md={5}>

                            <Form.Group controlId="formKreditorListe">
                                <BfFormLabel><FormattedMessage id="KreditorListeLabel" /></BfFormLabel>
                                <Form.Control
                                    ref={KreditorListe}
                                    disabled={kreditorSelectorsDisable()}
                                    as="select"
                                    multiple
                                    size="10" 
                                    name="tilgjengeligeKreditorer"
                                    value={formState.tilgjengeliteKreditorer}
                                    onChange={handleMultiSelectChange}
                                    onDoubleClick={leggTilKreditor}
                                    style={{
                                        height: '270px'
                                  }}
                                >
                                    {FilteredForeningKreditorListe()}

                                </Form.Control>
                                <BfFormLabelSm className="text-muted">
                                    <FormattedMessage id="KreditorListeSmall" />
                                </BfFormLabelSm>
                            </Form.Group>


                        </Col>

                        <Col xs={6} md={1}>
                            <BfFormLabel></BfFormLabel><br />
                            <BfButtonBlue style={{minWidth: '0px'}} variant="primary" onClick={leggTilKreditor} disabled={kreditorSelectorsDisable()}>
                                <FormattedMessage id="leggtil" />
                            </BfButtonBlue>
                        </Col>
                        <Col xs={6} md={1}>
                            <BfFormLabel></BfFormLabel><br />
                            <BfButtonBlue  style={{minWidth: '0px'}} variant="primary" onClick={trekkFraKreditor} disabled={kreditorSelectorsDisable()}>
                                <FormattedMessage id="trekkfra" />
                            </BfButtonBlue>
                        </Col>

                        <Col xs={12} md={5}>

                            <Form.Group controlId="formValgteKreditorer">
                                <BfFormLabel><FormattedMessage id="ValgteKreditorerLabel" /></BfFormLabel>
                                <Form.Control
                                    ref={valgtKreditorListe}
                                    disabled={kreditorSelectorsDisable()}                               
                                    as="select"
                                    multiple
                                    size="10" 
                                    name="valgteKreditorer"
                                    value={formState.valgteKreditorer}
                                    onChange={handleMultiSelectChange}
                                    style={showBorderSelect('kreditorListe')}                                   
                                >
                                    {FilteredKreditorListe()}                                                                    
                                   
                                </Form.Control>
                                <BfFormLabelSm><FormattedMessage id="ValgteKreditorerSmall" /></BfFormLabelSm>&nbsp;
                                <BfFormErrorSm>{extraErrors['kreditorListe']}</BfFormErrorSm>
                            </Form.Group>

                        </Col>


                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>

                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                <FormattedMessage id="Lagre" />
                            </BfButtonBlue>
                                                        
                            &nbsp;
                            <BfButtonBlue variant="primary" type="button" onClick={copyCurrentUser} disabled={kopierDisable()}>
                                <FormattedMessage id="Kopier" />
                            </BfButtonBlue>

                            &nbsp;
                            <BfButtonBlue variant="primary" type="button" onClick={visBekreftModal} disabled={!slettDisable()}>
                                <FormattedMessage id="Slett" />
                            </BfButtonBlue>

                            &nbsp;
                            <BfButtonBlue variant="primary" type="button" onClick={props.gotoSearchResult}>
                                <FormattedMessage id="Tilbake" />
                            </BfButtonBlue>


                        </Col>

                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />


                </YellowRoundedContainer>
            </Form>

            {debugInfo()}
           

        </IntlProvider>
    )
}

export default EditProfile