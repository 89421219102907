import React from 'react';
import styled from 'styled-components'
import colorConfig from '../colorConfig';

const FooterComponent = (props) => {

    return (
        <>   
            <Pf>
                <b>Bedrift</b>
            </Pf>
            <Af href="https://www.kred.no/integrasjon-okonomisystemer/" target="_blank">Systemintegrasjoner</Af><br/>
            <Af href="https://www.kred.no/nyheter/" target="_blank">Nyheter</Af><br/>
            <Af href="https://www.kred.no/bli-kunde/" target="_blank">Bli kunde</Af><br/>
            <Af href="https://www.kred.no/om-oss/" target="_blank">Om oss</Af><br/>
            <Af href="https://www.kred.no/personvernerklaering/" target="_blank">Cookies og personvern</Af><br/>
        </>
        
    )
}

export default FooterComponent

const Af = styled.a`
    color: ${colorConfig.lys_gul};
    font-family: 'Fellix', sans-serif;
    font-size: 20px;
    font-weight: 600; 
  &:hover {
    color: #be9695;
  }
  &:visited {
    color: ${colorConfig.lys_gul};
  }
`;


const Pf = styled.p`
    color: ${colorConfig.lys_gul};
    font-family: 'Fellix', sans-serif;
    font-size: 20px;
    font-weight: 400; 
`;
