import React, {Fragment} from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginContainer from '../containers/login/LoginContainer';
import { TokenLogin } from '../containers/login/TokenLogin'
import UserAdminContainer from '../containers/useradmin/UserAdminContainer';
import Welcome from '../containers/welcome/Welcome';
import {useAuth} from '../hooks/useAuth'
import ProfilContainer from '../containers/profil/ProfilContainer'
import OnBoardingContainer from '../containers/onBoarding/OnBoardingContainer'
import dnnContainer from '../containers/dnnSite/useDnnContainer'
import ResetPasswordContainer from '../containers/resetPassword/resetPasswordContainer'
import DnnSpinningContainer from '../containers/dnnSite/DnnSpinningContainer'
import LoginWelcome from '../containers/login/components/LoginWelcome';
import { TokenLoginCaseDet } from '../containers/login/TokenLoginCaseDet';
import NewCaseContainer from '../containers/caseNew/NewCaseContainer';
import NewCaseRedirect from '../containers/caseNew/NewCaseRedirect';
import OversiktContainer from '../containers/oversikt/OversiktContainer';
import FilebehandlingContainer from '../containers/filbehandling/FilbehandlingContainer';
import CasesMRUContainer from '../containers/CasesMRU/CasesMRUContainer';
import { SpinnerDnnLoginRedirect } from '../containers/dnnSite/dnnFunctions';
import HeadlessCmsContainer from '../containers/headlessCMS/HeadlessCmsContainer';
import BisNodeContainer from '../containers/bisNode/BisNodeContainer';
import InterestCalculatorContainer from '../containers/interestCalculator/InterestCalculatorContainer';
import { CaseListReduxContainer } from '../containers/caselist_redux/CaseListReduxContainer';
import DummyContainer from '../containers/dummy/DummyContainer';
import CaseDetailsContainerRedux from '../containers/caseDetailsRedux/CaseDetailsContainer';
import FakturaAdminContainer from '../containers/fakturaAdminContainer/FakturaAdminContainer';
import KontaktSideContainer from '../containers/kontaktSide/KontaktSideContainer';
import { useRefreshTokenAutoLogout } from '../components/LogoutWarning/useRefreshTokenAutoLogout';
import { PbiReportContainer } from '../containers/pbiReport/PbiReportContainer';
import PbiTabContainer from '../containers/pbiTabContainer/PbiTabContainer';
import WelcomeNoAccess from '../containers/WelcomeNoAccess/WelcomeNoAccess';
import MfaContainer from '../containers/mfa/MfaContainer';
import WsUserLoginContainer from '../containers/wsuserlogin/WsUserLoginContainer';
import CONFIG from '../config';
import StengelisteContainer from '../containers/stengeliste/StengelisteContainer';
import WebserviceContainer from '../containers/webservice/WebserviceContainer';


const KfRoutes = (props) => {
    const auth = useAuth();

    const isAdmin = () => {
        if (auth.isSupervisor())
            return true
        if (auth.isForeningSupervisor())
            return true
        return false
    }

    const { displayLogoutWarning } = useRefreshTokenAutoLogout()

    console.log('ForcemfaNow() returns :', auth.forceMfaNow())

    if (auth.forceMfaNow()) {
    //if (auth.user.forceMfaNow !== 'false') {
        //console.log("Du må sette opp mfa", auth)
        return (
            <Fragment>            
                <Switch>
                   <Route exact path="/KontaktSkjema" component={KontaktSideContainer} />
                   <Route exact path="/profil" component={ProfilContainer} />
                   <Route exact path="*" component={MfaContainer} />
                </Switch>
            </Fragment>            
        )
    }

    return (
        <Fragment>
            {displayLogoutWarning()}
            <Switch>
                {
                    (auth.isAuthenticated)
                        ? <Route exact path="/" component={LoginWelcome} />
                        : <Route exact path="/" component={Welcome} />
                }


                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/Filbehandling" component={FilebehandlingContainer} />
                        : <Route exact path="/Filbehandling" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && (auth.creditors.length > 0))
                        ? <Route exact path="/caselist" component={CaseListReduxContainer} />
                        : <Route exact path="/caselist" component={Welcome} />
                }

                {
                    (auth.isAuthenticated)
                        ? <Route exact path="/Dummy" component={DummyContainer} />
                        : <Route exact path="/Dummy" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/casesmru" component={CasesMRUContainer} />
                        : <Route exact path="/casesmru" component={Welcome} />
                }

                {  /* Profil  */
                    (auth.isAuthenticated)
                        ? <Route exact path="/profil" component={ProfilContainer} />
                        : <Route exact path="/profil" component={LoginContainer} />
                }

                {  /* MFA registreringng  */
                    (auth.isAuthenticated)
                        ? <Route exact path="/mfa" component={MfaContainer} />
                        : <Route exact path="/mfa" component={LoginContainer} />
                }


                <Route exact path="/onboarding" component={OnBoardingContainer} />
                
                {
                    (auth.isAuthenticated && !auth.isUser())
                        ? <Route exact path="/brukere" component={() => <UserAdminContainer resetTrigger={Math.random()} />} />
                        : <Route exact path="/brukere" component={Welcome} />
                }            

                {/* logout trengs ikke, det gjøres med auth.logOut rett fra menyen */}
                {/* <Route exact path="/login/:username?/:crNo?/:caseId?" component={LoginContainer} /> */}


                {/* Sendes til casedetails, eller via login bilde dersom ikke pålogget */}
                <Route exact path="/login/:crNo?/:caseId?" render={(props) => <LoginContainer {...props.match.params} />} />
                
                {/* Sendes til casedetails, eller via login bilde dersom ikke pålogget, varianten skal vekk */}
                <Route exact path="/login/:username?/:crNo?/:caseId?" render={(props) => <LoginContainer {...props.match.params} />} />

                <Route exact path="/tokenlogin/:token" render={(props) => <TokenLogin {...props.match.params} />} />
                <Route exact path="/tokenLoginCaseDet/:crNo/:caseId/:token" render={(props) => <TokenLoginCaseDet {...props.match.params} />} />

                <Route exact path="/BanqsoftPortal" component={SpinnerDnnLoginRedirect} />
                
                <Route exact path="/dnnlogin" component={dnnContainer} /> 

                <Route path="/resetpassword/:token/:email" render={(props) => <ResetPasswordContainer {...props.match.params} />}  />  
                
                <Route exact path="/dnntransfer" component={DnnSpinningContainer} />

                {CONFIG.WSUSER_TOKENLOGIN &&
                    <Route exact path="/wsuserlogin/:crNo/:caseId/:token" render={(props) => <WsUserLoginContainer {...props.match.params} />}  />  
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/CaseDetails/:crNo/:caseId" render={(props) => <CaseDetailsContainerRedux {...props.match.params} />} />
                        : <Route exact path="/CaseDetails/:crNo/:caseId" component={Welcome} />
                }
                
                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/CaseNew/init" component={NewCaseRedirect} />
                        : <Route exact path="/CaseNew/init" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/CaseNew" component={() => <NewCaseContainer resetTrigger={Math.random()} />} />
                        : <Route exact path="/CaseNew" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/Overview" component={OversiktContainer} />
                        : <Route exact path="/Overview" component={Welcome} />
                }

                {/* Start reports menu */}

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/Reports/Main" render={(props) => <PbiTabContainer />} />
                        : <Route exact path="/Reports/Main" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/Reports/Run/:reportId" render={(props) => <PbiReportContainer {...props.match.params} />} />
                        : <Route exact path="/Reports/Run/:reportId" component={Welcome} />
                }               

                {
                    (auth.isAuthenticated)
                        ? <Route exact path="/HeadlessCMS/:id" render={(props) => <HeadlessCmsContainer {...props.match.params} />} />
                        : <Route exact path="/HeadlessCMS/:id" component={Welcome} />
                }

                {
                    (auth.isAuthenticated)
                        ? <Route exact path="/KredittInformasjon" render={(props) => <BisNodeContainer {...props.match.params} />} />
                        : <Route exact path="/KredittInformasjon" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/fakturaadmin" component={FakturaAdminContainer} />
                        : <Route exact path="/fakturaadmin" component={Welcome} />
                }

                {
                    (auth.isAuthenticated && !isAdmin())
                        ? <Route exact path="/KontaktSkjema" component={KontaktSideContainer} />
                        : <Route exact path="/KontaktSkjema" component={KontaktSideContainer} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length === 0))
                    ? <Route exact path="/WelcomeNoAccess" component={WelcomeNoAccess} />
                    : <Route exact path="/WelcomeNoAccess" component={WelcomeNoAccess} />
                }

                {
                    (auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0))
                        ? <Route exact path="/stengeliste" component={StengelisteContainer} />
                        : <Route exact path="/stengeliste" component={Welcome} />
                }

                {
                    (auth.isAuthenticated)
                        ? <Route exact path="/utvikling" component={WebserviceContainer} />
                        : <Route exact path="/utvikling" component={Welcome} />
                }

                <Route exact path="/RenteKalkulator" component={InterestCalculatorContainer} />

            </Switch>
        </Fragment>
    )
}

export default KfRoutes